import fb from '../firebase'
import Swal from 'sweetalert2'
import moment from 'moment'
import _ from 'lodash'
moment.locale('es')
const databaseRef = fb.database()

const formatMonth = {
  0: '01',
  1: '02',
  2: '03',
  3: '04',
  4: '05',
  5: '06',
  6: '07',
  7: '08',
  8: '09',
  9: '10',
  10: '11',
  11: '12'
}

const getAbsoluteMonths = momentDate => {
  return momentDate.month() + momentDate.year() * 12
}

export const getReportsByCreatedAt = async (startDateUnix, stopDateUnix) => {
  const momentStartDate = moment(startDateUnix)
  const momentStopDate = moment(stopDateUnix)
  const stopAtMonth = moment(stopDateUnix).month()
  const stopAtYear = moment(stopDateUnix).year()
  const startMonths = getAbsoluteMonths(momentStartDate)
  const endMonths = getAbsoluteMonths(momentStopDate)
  const monthDifference = endMonths - startMonths
  const arrayMonths = _.range(monthDifference)
  const referencesArray = []
  const referenceStop = databaseRef
    .ref()
    .child(`finishedOrders/${stopAtYear}/${formatMonth[stopAtMonth]}`)
    .orderByChild('createdAt')
    .startAt(startDateUnix)
    .endAt(stopDateUnix)
    .once('value')
  referencesArray.push(referenceStop)
  arrayMonths.forEach(item => {
    let momentStopDateUnixClone = momentStopDate.clone()
    let stopDateSubtract = momentStopDateUnixClone.subtract(item + 1, 'month')
    let stopDateMonth = stopDateSubtract.month()
    let stopDateYear = stopDateSubtract.year()
    const referenceStart = databaseRef
      .ref()
      .child(`finishedOrders/${stopDateYear}/${formatMonth[stopDateMonth]}`)
      .orderByChild('createdAt')
      .startAt(startDateUnix)
      .endAt(stopDateUnix)
      .once('value')
    referencesArray.push(referenceStart)
  })
  return referencesArray
}

export const getOrdersCompleteByValue = async (
  value,
  type,
  startDateUnix,
  stopDateUnix
) => {
  const responseReports = await getReportsByCreatedAt(
    startDateUnix,
    stopDateUnix
  )
  return Promise.all(responseReports)
    .then(async result => {
      var historyOrders = {}
      var orders = {}
      _.range(responseReports.length).forEach(resultIndex => {
        if (result[resultIndex].exists()) {
          const orderData = result[resultIndex].val()
          Object.keys(orderData).forEach(key => {
            if (
              type === 'user' &&
              orderData[key].client.clientData.email === value &&
              key !== 'undefined'
            ) {
              orders = { ...orders, [key]: orderData[key] }
              historyOrders[orderData[key].client.clientId] = {
                orders,
                clientData: orderData[key].client.clientData
              }
            }
            if (
              type === 'order' &&
              orderData[key].id === value &&
              key !== 'undefined'
            ) {
              historyOrders[key] = orderData[key]
            }
          })
        }
      })
      return new Promise((resolve, reject) => {
        resolve(historyOrders)
      })
    })
    .finally(() => Swal.close())
    .catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Tu petición no tuvo exito, intentalo de nuevo!.`,
        showConfirmButton: true
      })
    })
}
export const getOrdersComplete = async (startDateUnix, stopDateUnix, city) => {
  const responseReports = await getReportsByCreatedAt(
    startDateUnix,
    stopDateUnix
  )
  return Promise.all(responseReports)
    .then(async result => {
      var historyOrders = {}
      _.range(responseReports.length).forEach(resultIndex => {
        if (result[resultIndex].exists()) {
          const orderData = result[resultIndex].val()
          Object.keys(orderData).forEach(key => {
            if (
              orderData[key].restaurant.city === city &&
              key !== 'undefined'
            ) {
              historyOrders[key] = orderData[key]
            }
          })
        }
      })
      return new Promise((resolve, reject) => {
        resolve(historyOrders)
      })
    })
    .finally(() => Swal.close())
    .catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Tu petición no tuvo exito, intentalo de nuevo!.`,
        showConfirmButton: true
      })
    })
}

export const getReportsRiders = async (startDateUnix, stopDateUnix, city) => {
  const responseReports = await getReportsByCreatedAt(
    startDateUnix,
    stopDateUnix
  )
  return Promise.all(responseReports)
    .then(async result => {
      var historyOrders = {}
      _.range(responseReports.length).forEach(resultIndex => {
        if (result[resultIndex].exists()) {
          const orderData = result[resultIndex].val()
          Object.keys(orderData).forEach(key => {
            if (
              orderData[key].restaurant.city === city &&
              orderData[key].status !== 'order_cancelled' &&
              key !== 'undefined'
            ) {
              historyOrders[key] = orderData[key]
            }
          })
        }
      })
      const promise = await _createReportRidersByDateNew(
        historyOrders,
        startDateUnix,
        stopDateUnix,
        city
      )

      var objectOrders = {}
      let objectResults = {}
      var allObjects = {}
      var comissionPaypalCost = 6

      Object.keys(promise).forEach(key => {
        objectResults[key] = {
          objectCalc: {
            debt: 0,
            paypalAll: 0,
            cashAll: 0,
            cardAll: 0,
            sumDeliveryTotal: 0,
            discountAll: 0
          },
          ...objectResults[key]
        }
        objectOrders[key] = {
          ...objectOrders[key],
          ...promise[key].orders
        }
        promise[key].orders = objectOrders[key]
        // Descuento
        objectResults[key]['objectCalc']['discountAll'] =
          promise[key].discountAll +
          objectResults[key]['objectCalc']['discountAll']
        // Total de costos de envios
        objectResults[key]['objectCalc']['sumDeliveryTotal'] =
          promise[key].sumDeliveryTotal +
          objectResults[key]['objectCalc']['sumDeliveryTotal']
        // Deuda
        objectResults[key]['objectCalc']['debt'] =
          promise[key].debt + objectResults[key]['objectCalc']['debt']
        // Total de paypal
        objectResults[key]['objectCalc']['paypalAll'] =
          promise[key].paypalAll + objectResults[key]['objectCalc']['paypalAll']
        // Total de efectivo
        objectResults[key]['objectCalc']['cashAll'] =
          promise[key].cashAll + objectResults[key]['objectCalc']['cashAll']
        // Total de tarjeta
        objectResults[key]['objectCalc']['cardAll'] =
          promise[key].cardAll + objectResults[key]['objectCalc']['cardAll']
        // Asignar a objecto promise el total de costos de envios
        promise[key].sumDeliveryTotal =
          objectResults[key]['objectCalc']['sumDeliveryTotal']

        // Asignar a objecto promise la deuda
        promise[key].debt = objectResults[key]['objectCalc']['debt']

        // Asignar a objecto promise paypal
        promise[key].paypalAll = objectResults[key]['objectCalc']['paypalAll']

        // Asignar a objecto promise efectivo
        promise[key].cashAll = objectResults[key]['objectCalc']['cashAll']

        // Asignar a objecto promise tarjeta
        promise[key].cardAll = objectResults[key]['objectCalc']['cardAll']
        // Obtener el valor de paypal con comision de paypal
        var getComissionPaypal =
          promise[key].paypalAll * comissionPaypalCost / 100
        var getComissionPaycode =
          promise[key].cardAll * comissionPaypalCost / 100
        // Total
        promise[key]['total'] =
          promise[key].paypalAll -
          getComissionPaypal +
          (promise[key].cardAll - getComissionPaycode) +
          (promise[key].debt || 0) +
          promise[key].discountAll
        // Deuda bool

        promise[key]['yumDebt'] = promise[key].total >= 0

        allObjects = {
          ...allObjects,
          ...promise
        }
      })
      return new Promise((resolve, reject) => {
        resolve(allObjects)
      })
    })
    .finally(() => Swal.close())
    .catch(err => {
      Swal.fire({
        position: 'center',
        type: 'error',
        title: `Tu petición no tuvo exito, intentalo de nuevo!.`,
        showConfirmButton: false,
        timer: 2500
      })
    })
}
export const getReports = async (startDateUnix, stopDateUnix, city) => {
  var IVA = 16
  const responseReports = await getReportsByCreatedAt(
    startDateUnix,
    stopDateUnix
  )
  return Promise.all(responseReports).then(async result => {
    var historyOrders = {}
    _.range(responseReports.length).forEach(resultIndex => {
      if (result[resultIndex].exists()) {
        const orderData = result[resultIndex].val()
        Object.keys(orderData).forEach(key => {
          if (
            orderData[key].restaurant.city === city &&
            orderData[key].status !== 'order_cancelled' &&
            key !== 'undefined'
          ) {
            historyOrders[key] = orderData[key]
          }
        })
      }
    })
    const promise = await _createReportByDateNew(
      historyOrders,
      startDateUnix,
      stopDateUnix,
      city
    )

    var objectOrders = {}
    let objectResults = {}
    var allObjects = {}
    Object.keys(promise).forEach(key => {
      if (key !== 'undefined') {
        objectResults[key] = {
          objectCalc: {
            ordersTotal: 0,
            netTotal: 0,
            debt: 0,
            comissionByPaypal: 0,
            comissionByCash: 0,
            comissionByCard: 0,
            comissionTotal: 0,
            paypalAll: 0,
            cashAll: 0,
            cardAll: 0
          },
          ...objectResults[key]
        }
        objectOrders[key] = {
          ...objectOrders[key],
          ...promise[key].orders
        }
        promise[key].orders = objectOrders[key]

        objectResults[key]['objectCalc']['ordersTotal'] =
          promise[key].ordersTotal +
          objectResults[key]['objectCalc']['ordersTotal']
        objectResults[key]['objectCalc']['netTotal'] =
          promise[key].netTotal + objectResults[key]['objectCalc']['netTotal']
        objectResults[key]['objectCalc']['debt'] =
          promise[key].debt + objectResults[key]['objectCalc']['debt']
        objectResults[key]['objectCalc']['comissionByPaypal'] =
          promise[key].comissionByPaypal +
          objectResults[key]['objectCalc']['comissionByPaypal']
        objectResults[key]['objectCalc']['comissionByCash'] =
          promise[key].comissionByCash +
          objectResults[key]['objectCalc']['comissionByCash']
        objectResults[key]['objectCalc']['comissionByCard'] =
          promise[key].comissionByCard +
          objectResults[key]['objectCalc']['comissionByCard']
        objectResults[key]['objectCalc']['comissionTotal'] =
          objectResults[key]['objectCalc']['comissionTotal'] +
          promise[key].comissionTotal
        objectResults[key]['objectCalc']['paypalAll'] =
          promise[key].paypalAll + objectResults[key]['objectCalc']['paypalAll']
        objectResults[key]['objectCalc']['cashAll'] =
          promise[key].cashAll + objectResults[key]['objectCalc']['cashAll']
        objectResults[key]['objectCalc']['cardAll'] =
          promise[key].cardAll + objectResults[key]['objectCalc']['cardAll']
        promise[key].ordersTotal =
          objectResults[key]['objectCalc']['ordersTotal']
        promise[key].netTotal = objectResults[key]['objectCalc']['netTotal']
        promise[key].debt = objectResults[key]['objectCalc']['debt']
        promise[key].comissionByPaypal =
          objectResults[key]['objectCalc']['comissionByPaypal']
        promise[key].comissionByCash =
          objectResults[key]['objectCalc']['comissionByCash']
        promise[key].comissionByCard =
          objectResults[key]['objectCalc']['comissionByCard']
        promise[key].comissionTotal =
          objectResults[key]['objectCalc']['comissionTotal']
        promise[key].paypalAll = objectResults[key]['objectCalc']['paypalAll']
        promise[key].cashAll = objectResults[key]['objectCalc']['cashAll']
        promise[key].cardAll = objectResults[key]['objectCalc']['cardAll']

        allObjects = {
          ...allObjects,
          ...promise
        }
      }
    })
    Object.keys(allObjects).forEach(restaurantKey => {
      // >>>>>>>>>>>>>>>>>>>>> Taxs <<<<<<<<<<<<<<<<<<<<<<<
      var restaurant = allObjects[restaurantKey]
      var taxPaypal = restaurant.comissionByPaypal * IVA / 100
      var taxCash = restaurant.comissionByCash * IVA / 100
      var taxCard = restaurant.comissionByCard * IVA / 100

      restaurant.taxCash = taxCash
      restaurant.taxPaypal = taxPaypal
      restaurant.taxCard = taxCard

      var taxWithPaypal = restaurant.comissionByPaypal + taxPaypal
      var taxWithCash = restaurant.comissionByCash + taxCash
      var taxWithCard = restaurant.comissionByCard + taxCard

      restaurant.withTaxCash = taxWithCash
      restaurant.withTaxPaypal = taxWithPaypal
      restaurant.withTaxCard = taxWithCard

      var billable = taxWithPaypal + taxWithCash + taxWithCard

      var totalNet = restaurant.paypalAll + restaurant.cardAll - billable
      // var totalNetCard = restaurant.cardAll -

      restaurant.billable = billable
      restaurant.totalNet = totalNet

      let getTotal = totalNet + (restaurant.debt || 0.0)

      restaurant.total = getTotal
      restaurant.yumDebt = getTotal > 0
      // >>>>>>>>>>>>>>>>>>>>> Taxs <<<<<<<<<<<<<<<<<<<<<<<
    })
    return new Promise((resolve, reject) => {
      resolve(allObjects)
    })
  })
}

const _createReportRidersByDateNew = (
  results,
  dayString,
  dayEndString,
  city
) => {
  var ridersObject = {}
  Object.values(results).forEach((data, index) => {
    if (
      data.attendedBy &&
      data.status !== 'order_cancelled' &&
      data.restaurant.city === city
    ) {
      if (moment(data.createdAt).isBetween(dayString, dayEndString)) {
        let key = data.attendedBy.deliveryId
        let orderTotal = data.orderTotal
        let orderId = data.id || data.orderNumber
        let payType = data.payType
        let createdAt = data.createdAt
        let discount = data.discount
        orderTotal['discount'] = discount
        orderTotal['orderId'] = orderId
        orderTotal['typePayment'] =
          payType === 'paypal'
            ? 'Paypal'
            : payType === 'cash' ? 'Efectivo' : 'Tarjeta'
        orderTotal['createdAt'] = createdAt
        // >>>> orders
        let orders = {}
        orders[orderId] = orderTotal
        let paypalAll = 0
        let cashAll = 0
        let cardAll = 0
        let sumDeliveryTotal = 0
        let discountAll = 0
        if (key) {
          if (ridersObject[key]) {
            let deliveryCost = orderTotal.deliveryCost
            // Add Discount
            if (discount) {
              if (discount.type === 'shipping') {
                deliveryCost =
                  discount.deliveryCost >= discount.discountValue
                    ? discount.discountValue
                    : discount.deliveryCost
                if (payType === 'paypal') {
                  cashAll = ridersObject[key].cashAll
                  cardAll = ridersObject[key].cardAll
                  paypalAll =
                    ridersObject[key].paypalAll + discount.deliveryCost
                  discountAll = ridersObject[key].discountAll
                } else if (payType === 'card') {
                  cardAll = ridersObject[key].cardAll + discount.deliveryCost
                  cashAll = ridersObject[key].cashAll
                  paypalAll = ridersObject[key].paypalAll
                  discountAll = ridersObject[key].discountAll
                } else {
                  cashAll = ridersObject[key].cashAll + orderTotal.deliveryCost
                  paypalAll = ridersObject[key].paypalAll
                  cardAll = ridersObject[key].cardAll
                  discountAll = ridersObject[key].discountAll + deliveryCost
                }
              } else {
                if (payType === 'paypal') {
                  paypalAll = ridersObject[key].paypalAll + deliveryCost
                  cashAll = ridersObject[key].cashAll
                  cardAll = ridersObject[key].cardAll
                  discountAll = ridersObject[key].discountAll
                } else if (payType === 'card') {
                  cardAll = ridersObject[key].cardAll + deliveryCost
                  cashAll = ridersObject[key].cashAll
                  paypalAll = ridersObject[key].paypalAll
                  discountAll = ridersObject[key].discountAll
                } else {
                  cashAll = ridersObject[key].cashAll + deliveryCost
                  paypalAll = ridersObject[key].paypalAll
                  cardAll = ridersObject[key].cardAll
                  discountAll = ridersObject[key].discountAll
                }
              }
            } else {
              // sum
              if (payType === 'paypal') {
                paypalAll = ridersObject[key].paypalAll + deliveryCost
                cashAll = ridersObject[key].cashAll
                cardAll = ridersObject[key].cardAll
                discountAll = ridersObject[key].discountAll
              } else if (payType === 'card') {
                cardAll = ridersObject[key].cardAll + deliveryCost
                cashAll = ridersObject[key].cashAll
                paypalAll = ridersObject[key].paypalAll
                discountAll = ridersObject[key].discountAll
              } else {
                cashAll = ridersObject[key].cashAll + deliveryCost
                paypalAll = ridersObject[key].paypalAll
                cardAll = ridersObject[key].cardAll
                discountAll = ridersObject[key].discountAll
              }
            }
            // TotalSum
            sumDeliveryTotal =
              ridersObject[key].sumDeliveryTotal + orderTotal.deliveryCost

            ridersObject[key] = {
              orders: {
                ...ridersObject[key].orders,
                ...orders
              },
              attendedBy: data.attendedBy,
              sumDeliveryTotal,
              paypalAll,
              cashAll,
              cardAll,
              discountAll
            }
          } else {
            let deliveryCost = orderTotal.deliveryCost
            // Add Discount
            if (!_.isEmpty(discount)) {
              if (discount.type === 'shipping') {
                deliveryCost =
                  discount.deliveryCost >= discount.discountValue
                    ? discount.discountValue
                    : discount.deliveryCost
                if (payType === 'paypal') {
                  paypalAll = discount.deliveryCost
                } else if (payType === 'card') {
                  cardAll = discount.deliveryCost
                } else {
                  cashAll = orderTotal.deliveryCost
                  discountAll = deliveryCost
                }
              } else {
                if (payType === 'paypal') {
                  paypalAll = deliveryCost
                } else if (payType === 'card') {
                  cardAll = deliveryCost
                } else {
                  cashAll = deliveryCost
                }
              }
            } else {
              if (payType === 'paypal') {
                paypalAll = deliveryCost
              } else if (payType === 'card') {
                cardAll = deliveryCost
              } else {
                cashAll = deliveryCost
              }
            }
            // TotalSum
            sumDeliveryTotal = orderTotal.deliveryCost

            ridersObject[key] = {
              orders: orders,
              attendedBy: data.attendedBy,
              sumDeliveryTotal,
              cashAll,
              paypalAll,
              cardAll,
              discountAll
            }
          }
        }
      }
    }
  })
  return ridersObject
}

const getComissionByFirebase = async restaurantId => {
  const snapshot = await databaseRef
    .ref(`restaurants`)
    .child(restaurantId)
    .child('comissionPercentage')
    .once('value')
  return snapshot.val()
}

const _createReportByDateNew = async (
  results,
  dayString,
  dayEndString,
  city
) => {
  var restaurantObject = {}
  var restaurantsByComission = await setComissionToRestaurants(
    results,
    dayString,
    dayEndString,
    city
  )
  Object.values(results).forEach(data => {
    if (
      data.status !== 'order_cancelled' &&
      moment(data.createdAt).isBetween(dayString, dayEndString) &&
      data.restaurant.city === city
    ) {
      let key = data.restaurantId
      let orderNumber = data.orderNumber
      const {
        name,
        email,
        rfc,
        partner,
        bank,
        CLABE,
        bankAccount,
        debt,
        city
      } = data.restaurant
      const restaurantName = name
      const restaurantCity = city
      let comissionPercentage = restaurantsByComission[key].comissionPercentage
      const percentage = comissionPercentage

      let uid = data.restaurantId
      let createdAt = data.createdAt
      let payType = data.payType
      // >>>> order total
      let orderTotal = data.orderTotal
      let discount = data.discount
      orderTotal['discount'] = discount
      orderTotal['typePayment'] =
        payType === 'paypal'
          ? 'Paypal'
          : payType === 'cash' ? 'Efectivo' : 'Tarjeta'
      orderTotal['createdAt'] = createdAt
      orderTotal['uid'] = orderNumber
      orderTotal['dishes'] = data.dishes || []
      const clientData = data.client.clientData
        ? _.pick(data.client.clientData, [
          'email',
          'name',
          'lastName',
          'motherLastName',
          'phone',
          ''
        ])
        : false
      orderTotal['client'] = clientData || null
      // >>>> orders
      let orders = {}
      orders[orderNumber] = orderTotal
      var ordersTotal = 0
      let comissionByPaypal = 0
      let comissionByCash = 0
      let comissionByCard = 0
      let comissionTotal = 0
      let paypalAll = 0
      let cashAll = 0
      let cardAll = 0
      let discountCashAll = 0
      let discountPaypalAll = 0
      let discountCardAll = 0

      // >>>>>>>>> if exists object by Restaurant >>>>>>>>>>>>>
      if (restaurantObject[key]) {
        restaurantObject[key] = {
          ...restaurantObject[key]
        }

        if (restaurantObject[key].hasOwnProperty('ordersTotal')) {
          // add ordersTotal
          ordersTotal = data.orderTotal.subtotal
          // restaurantObject[key].ordersTotal + data.orderTotal.subtotal
          // Add Discount
          if (discount) {
            if (discount.type === 'food') {
              ordersTotal = discount.foodCost
              if (orderTotal.typePayment === 'Paypal') {
                discountPaypalAll =
                  restaurantObject[key].discountPaypalAll +
                  discount.discountValue
                discountCashAll = restaurantObject[key].discountCashAll
              } else if (orderTotal.typePayment === 'Tarjeta') {
                // Check card discount
                discountCardAll =
                  restaurantObject[key].discountCardAll + discount.discountValue
              } else {
                discountCashAll =
                  restaurantObject[key].discountCashAll + discount.discountValue
                discountPaypalAll = restaurantObject[key].discountPaypalAll
              }
            }
          } else {
            discountPaypalAll = restaurantObject[key].discountPaypalAll
            discountCashAll = restaurantObject[key].discountCashAll
            discountCardAll = restaurantObject[key].discountCardAll
          }
          // //

          // add comission

          const getNumberPercetage = parseInt(percentage.split('%')[0], 10)
          const getComission = ordersTotal * getNumberPercetage / 100
          orderTotal['comissionTotal'] = getComission
          orders[orderNumber] = orderTotal

          // comissin by typePayment
          if (orderTotal.typePayment === 'Paypal') {
            // add all paypal
            paypalAll = restaurantObject[key].paypalAll + ordersTotal
            cashAll = restaurantObject[key].cashAll
            cardAll = restaurantObject[key].cardAll
            comissionByPaypal =
              restaurantObject[key].comissionByPaypal + getComission
            comissionByCash = restaurantObject[key].comissionByCash
            comissionByCard = restaurantObject[key].comissionByCard
          } else if (orderTotal.typePayment === 'Tarjeta') {
            cardAll = restaurantObject[key].cardAll + ordersTotal
            cashAll = restaurantObject[key].cashAll
            paypalAll = restaurantObject[key].paypalAll
            comissionByCash = restaurantObject[key].comissionByCash
            comissionByPaypal = restaurantObject[key].comissionByPaypal
            comissionByCard =
              restaurantObject[key].comissionByCard + getComission
          } else {
            // add all cash
            cashAll = restaurantObject[key].cashAll + ordersTotal
            paypalAll = restaurantObject[key].paypalAll
            cardAll = restaurantObject[key].cardAll
            comissionByPaypal = restaurantObject[key].comissionByPaypal
            comissionByCard = restaurantObject[key].comissionByCard
            comissionByCash =
              restaurantObject[key].comissionByCash + getComission
          }

          // add comission total
          comissionTotal = restaurantObject[key].comissionTotal + getComission
          // total
          restaurantObject[key] = {
            orders: {
              ...restaurantObject[key].orders,
              ...orders
            },
            ordersTotal,
            debt,
            percentage,
            restaurantName,
            restaurantCity,
            name: partner,
            uid,
            bank,
            CLABE,
            bankAccount,
            email,
            rfc,
            comissionTotal,
            paypalAll,
            discountPaypalAll,
            comissionByPaypal,
            cashAll,
            discountCashAll,
            comissionByCash,
            cardAll,
            discountCardAll,
            comissionByCard
          }
        }
      } else {
        // add ordersTotal
        ordersTotal = data.orderTotal.subtotal
        // Add Discount
        if (discount) {
          if (discount.type === 'food') {
            ordersTotal = discount.foodCost
            if (orderTotal.typePayment === 'Paypal') {
              discountPaypalAll = discount.discountValue
            } else if (orderTotal.typePayment === 'Tarjeta') {
              discountCardAll = discount.discountValue
            } else {
              discountCashAll = discount.discountValue
            }
          }
        }
        // add comission
        const getNumberPercetage = parseInt(percentage.split('%')[0], 10)
        const getComission = ordersTotal * getNumberPercetage / 100
        orderTotal['comissionTotal'] = getComission
        orders[orderNumber] = orderTotal
        // comissin by payType
        if (orderTotal.typePayment === 'Paypal') {
          comissionByPaypal = getComission
          paypalAll = ordersTotal
        } else if (orderTotal.typePayment === 'Tarjeta') {
          comissionByCard = getComission
          cardAll = ordersTotal
        } else {
          comissionByCash = getComission
          cashAll = ordersTotal
        }

        // add comission total
        comissionTotal = getComission

        restaurantObject[key] = {}
        restaurantObject[key] = {
          orders: orders,
          ordersTotal,
          debt,
          percentage,
          restaurantName,
          restaurantCity,
          name: partner,
          uid,
          bank,
          CLABE,
          bankAccount,
          email,
          rfc,
          comissionByCash,
          comissionByPaypal,
          comissionByCard,
          comissionTotal,
          paypalAll,
          cashAll,
          cardAll,
          discountPaypalAll,
          discountCashAll,
          discountCardAll
        }
      }
    }
  })
  return restaurantObject
}

const setComissionToRestaurants = async (
  results,
  dayString,
  dayEndString,
  city
) => {
  var restaurantObject = {}
  await Promise.all(
    Object.values(results).map(async data => {
      if (
        data.status !== 'order_cancelled' &&
        moment(data.createdAt).isBetween(dayString, dayEndString) &&
        data.restaurant.city === city
      ) {
        const key = data.restaurantId
        const comissionPercentage = await getComissionByFirebase(key)
        restaurantObject[key] = {
          comissionPercentage
        }
      }
    })
  )
  return restaurantObject
}
